import React, { useContext, useEffect, useRef } from 'react'
import { ProductSearchPopupCtx } from '../context'
import Typography from '../../Typography'
import Input from '../../Input'
import Button from '../../Button'
import SearchStoreIcon from '../../Icons/SearchStoreIcon'
import IProductSearchForm from './interfaces'
import styles from '../productSearchModal.module.scss'
import Loading from '../../Loading'
import SearchAlt from '../../Icons/SearchAlt'
import Chip from '../../Chip'

const productSearchDiction: Record<string, string> = {
  title: 'Product Search',
  subtitle: 'Search Product Name, SKU'
}

const ProductSearchForm = ({
  isModalOpen,
  title,
  subTitle,
  analyticsPrefixId,
  variant = 'withHeaderAndSubmitButton',
  chipTitle,
  chipLabels
}: IProductSearchForm) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const {
    searchTerm,
    isLoading,
    handleSubmit,
    handleReset,
    handleKeyDown,
    handleSearchTerm,
    handleChipClick,
    selectedChips
  } = useContext(ProductSearchPopupCtx)

  const {
    productSearchFormTitle,
    productSearchFormSubTitle,
    productSearchFormInputContainer,
    productSearchFormInput,
    productSearchSpinnerIconWrapper,
    productSearchFormButtonGroup,
    productSearchFormBtn,
    productSearchFormContainer
  } = styles
  const { title: defaultTitle, subtitle: defaultSubtitle } = productSearchDiction

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        inputRef?.current?.focus?.()
      }, 500)
    }
  }, [isModalOpen])
  const searchFormIcon = <SearchAlt width={17.5} height={17.5} fillColor="#B3B5B7" />

  return (
    <>
      {variant === 'withHeaderAndSubmitButton' ? (
        <>
          <div className={productSearchFormContainer}>
            <SearchStoreIcon />
            <Typography className={productSearchFormTitle} variant="h5">
              {title || defaultTitle}
            </Typography>
          </div>
          <Typography className={productSearchFormSubTitle} variant="paragraph-sm">
            {subTitle || defaultSubtitle}
          </Typography>
          <div className={productSearchFormInputContainer}>
            <div className={productSearchFormInput}>
              <Input
                ref={inputRef}
                autoComplete="off"
                data-testid="product-search-input"
                name="search"
                aria-label="search"
                value={searchTerm}
                variant="search"
                rightSide={isLoading ? <Loading size="sm" color="#1b244d" /> : null}
                rightSideWrapperClass={productSearchSpinnerIconWrapper}
                onChange={handleSearchTerm}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className={productSearchFormButtonGroup}>
              <Button
                id={`${analyticsPrefixId}_reset_button`}
                btnType="tertiary"
                className={productSearchFormBtn}
                onClick={handleReset}
                data-testid="reset-button"
              >
                Reset
              </Button>
              <Button
                id={`${analyticsPrefixId}_submit_button`}
                btnType="primary-blue"
                className={productSearchFormBtn}
                disabled={searchTerm?.length <= 0}
                data-testid="submit-button"
                onClick={handleSubmit}
              >
                Search
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.searchContainer}>
            <Input
              className={styles.searchInput}
              ref={inputRef}
              autoComplete="off"
              data-testid="product-search-input"
              placeholder="Search for a product here..."
              name="search"
              aria-label="search"
              value={searchTerm}
              variant="search"
              rightSide={isLoading ? <Loading size="sm" color="#1b244d" /> : null}
              rightSideWrapperClass={productSearchSpinnerIconWrapper}
              leftSide={searchFormIcon}
              onChange={handleSearchTerm}
              onKeyDown={handleKeyDown}
            />
            <div className={styles.dividerRow} />
          </div>
          {/* <div className={clsx(styles.redesignModalRow, styles.content)}> */}
          <div className={styles.redesignModalRow}>
            <Typography variant="paragraph">{chipTitle}</Typography>
            <div className={styles.chipRow}>
              {chipLabels &&
                chipLabels.map((label, index) => {
                  const variant = selectedChips.includes(label) ? 'selected-redesign' : 'grey-redesign'
                  return (
                    <Chip
                      key={index}
                      label={label}
                      variant={variant}
                      onClick={handleChipClick}
                      selected={selectedChips.includes(label)}
                    />
                  )
                })}
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default ProductSearchForm
