import React from 'react'
import styles from './productSearchModal.module.scss'
import ProductSearchList from './ProductSearchList'
import ProductSearchForm from './ProductSearchForm'
import { ProductSearchProvider } from './context/provider'
import IProductSearchModal from './interfaces/productSearchModal'
import Modal from '../Modal'
import { fakeProductSearchResults } from './fakeProductSearchData'

const ProductSearchModal = ({
  enableFakeData = false,
  fakeSearchData = [],
  isOpen = true,
  title,
  subTitle,
  chipTitle,
  chipLabels,
  getProductSearchResult,
  onSuccessFullSearch,
  onClose = () => null,
  onListItemClick,
  analyticsPrefixId,
  variant = 'current',
  ...rest
}: IProductSearchModal) => {
  return (
    <>
      {variant === 'current' ? (
        <Modal
          isOpen={isOpen}
          zIndex={1002}
          closeButtonClass={styles.close}
          childrenClass={styles.noContentBorder}
          onClose={onClose}
          className={styles.wrapperProductSearchModal}
        >
          <ProductSearchProvider
            enableFakeData={enableFakeData}
            fakeSearchData={fakeSearchData}
            getProductSearchResult={getProductSearchResult}
            onListItemClick={onListItemClick}
            onSuccessFullSearch={onSuccessFullSearch}
            isModalOpen={isOpen}
            {...rest}
          >
            <div className={styles.productSearchContentContainer}>
              <ProductSearchForm
                isModalOpen={isOpen}
                title={title}
                subTitle={subTitle}
                analyticsPrefixId={analyticsPrefixId}
              />
            </div>
            <ProductSearchList analyticsPrefixId={analyticsPrefixId} />
          </ProductSearchProvider>
        </Modal>
      ) : (
        <Modal
          isRedesign
          isOpen={isOpen}
          zIndex={1002}
          onClose={onClose}
          className={styles.redesignModalWrapper}
          header={title}
          subheader={subTitle}
          borderStyle="redesign-round"
        >
          <ProductSearchProvider
            enableFakeData={enableFakeData}
            fakeSearchData={fakeSearchData}
            getProductSearchResult={getProductSearchResult}
            onListItemClick={onListItemClick}
            onSuccessFullSearch={onSuccessFullSearch}
            isModalOpen={isOpen}
            {...rest}
          >
            <ProductSearchForm
              variant="withoutHeaderAndSubmitButton"
              isModalOpen={isOpen}
              analyticsPrefixId={analyticsPrefixId}
              chipTitle={chipTitle}
              chipLabels={chipLabels}
            />
            <ProductSearchList analyticsPrefixId={analyticsPrefixId} variant="redesigned" />
          </ProductSearchProvider>
        </Modal>
      )}
    </>
  )
}

export { fakeProductSearchResults }
export default ProductSearchModal
