import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fill?: string
}

const PlusRedesign: React.FunctionComponent<Props> = ({ width = '24', height = '24', fill = '#2D2926' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill={fill} />
  </svg>
)

export default PlusRedesign
