import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  stroke?: string
}

const ProductTypeBedding: React.FunctionComponent<Props> = ({ width = '16', height = '16', stroke = '#626366' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7668 9.30419H5.39C5.00353 9.30419 4.23058 9.0723 4.23058 8.14477C4.23058 7.21723 5.00353 6.98534 5.39 6.98534H12.6364M12.6364 6.98534C13.3127 7.08196 14.6654 7.681 14.6654 9.30419C14.6654 10.822 13.4826 11.4443 12.7761 11.5977C12.6836 11.6178 12.5889 11.623 12.4942 11.623H4.42862C4.29692 11.623 4.16503 11.6119 4.03793 11.5774C3.02942 11.3032 1.33203 10.3181 1.33203 8.14477C1.33203 5.97148 3.02942 4.98637 4.03793 4.71217C4.16503 4.67762 4.29692 4.6665 4.42862 4.6665H12.3465C12.9262 4.6665 13.2161 5.39114 13.2161 5.681C13.2161 6.40563 13.2161 6.69549 12.6364 6.98534Z"
      stroke={stroke}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ProductTypeBedding
