import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fill?: string
}

const ProductTypeFurniture: React.FunctionComponent<Props> = ({ width = '16', height = '16', fill = '#626366' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3346 5.33333V4C13.3346 2.9 12.4346 2 11.3346 2H4.66797C3.56797 2 2.66797 2.9 2.66797 4V5.33333C1.56797 5.33333 0.667969 6.23333 0.667969 7.33333V10.6667C0.667969 11.7667 1.56797 12.6667 2.66797 12.6667V13.3333C2.66797 13.7 2.96797 14 3.33464 14C3.7013 14 4.0013 13.7 4.0013 13.3333V12.6667H12.0013V13.3333C12.0013 13.7 12.3013 14 12.668 14C13.0346 14 13.3346 13.7 13.3346 13.3333V12.6667C14.4346 12.6667 15.3346 11.7667 15.3346 10.6667V7.33333C15.3346 6.23333 14.4346 5.33333 13.3346 5.33333ZM4.0013 4C4.0013 3.63333 4.3013 3.33333 4.66797 3.33333H11.3346C11.7013 3.33333 12.0013 3.63333 12.0013 4V5.85333C11.5946 6.22 11.3346 6.74667 11.3346 7.33333V8.66667H4.66797V7.33333C4.66797 6.74667 4.40797 6.22 4.0013 5.85333V4ZM14.0013 10.6667C14.0013 11.0333 13.7013 11.3333 13.3346 11.3333H2.66797C2.3013 11.3333 2.0013 11.0333 2.0013 10.6667V7.33333C2.0013 6.96667 2.3013 6.66667 2.66797 6.66667C3.03464 6.66667 3.33464 6.96667 3.33464 7.33333V10H12.668V7.33333C12.668 6.96667 12.968 6.66667 13.3346 6.66667C13.7013 6.66667 14.0013 6.96667 14.0013 7.33333V10.6667Z"
      fill={fill}
    />
  </svg>
)

export default ProductTypeFurniture
