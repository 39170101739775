import { useSetAtom } from 'jotai'
import { RESET, useResetAtom } from 'jotai/utils'
import { useCallback } from 'react'
import {
  clientelingTabAtom,
  initialLeadAtomBase,
  leadAtomBase,
  noteDetailsAtom,
  savedAtom,
  tabAtom,
  careTabAtom
} from '../../atoms'

const useResetRestAtoms = () => {
  const resetLead = useResetAtom(leadAtomBase)
  const resetInitialLead = useResetAtom(initialLeadAtomBase)
  const setNoteDetails = useSetAtom(noteDetailsAtom)
  const setSaved = useSetAtom(savedAtom)
  const setTab = useSetAtom(tabAtom)
  const setCareTab = useSetAtom(careTabAtom)
  const setClientelingTab = useSetAtom(clientelingTabAtom)

  const resetAtoms = useCallback(() => {
    resetLead()
    resetInitialLead()
    setNoteDetails(RESET)
    setSaved(RESET)
    setTab(RESET)
    setCareTab(RESET)
    setClientelingTab(RESET)
  }, [resetLead, resetInitialLead, setNoteDetails, setSaved, setTab, setCareTab, setClientelingTab])

  return {
    resetAtoms
  }
}

export default useResetRestAtoms
