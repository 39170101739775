import React, { useContext } from 'react'
import clsx from 'clsx'
import { useAtom } from 'jotai'
import ProductSearchListItem from './ProductSearchListItem'
import styles from './productSearchList.module.scss'
import { ProductSearchResultItem } from '../schema'
import { ProductSearchPopupCtx } from '../context'
import Typography from '../../Typography'
import Button from '../../Button'
import { ProductType } from '../../consts'
import ProductTypeMattress from '../../Icons/ProductTypeMattress'
import ProductTypeBedding from '../../Icons/ProductTypeBedding'
import ProductTypeFoundation from '../../Icons/ProductTypeFoundation'
import ProductTypeFurniture from '../../Icons/ProductTypeFurniture'
import ProductTypePillow from '../../Icons/ProductTypePillow'
import { productSearchAtom } from '../../atoms/productSearchAtom'
import PlusRedesign from '../../Icons/PlusRedesign'
import CheckMark from '../../Icons/CheckMark'
import CopyButton from '../../CopyButton'

const ProductSearchList = ({
  analyticsPrefixId,
  variant = 'current'
}: {
  analyticsPrefixId?: string
  variant?: 'current' | 'redesigned'
}) => {
  const { searchTerm, isLoading, searchResults } = useContext(ProductSearchPopupCtx)
  const [productSearchResults, setProductSearchResults] = useAtom(productSearchAtom)

  const {
    productSearchList,
    productSearchinfoMsg,
    productSearchListTitle,
    productSearchNoResultMsg,
    productSearchListContainer
  } = styles

  const searchInputNotEmpty = searchTerm?.length > 0
  const hasSearchResult = searchResults?.length > 0

  const isSearched = (hasSearchResult || isLoading) && searchInputNotEmpty

  const getHighlightedName = (name: string, searchKeyword: string): React.ReactNode => {
    if (!searchKeyword) return name

    const parts = name.split(new RegExp(`(${searchKeyword})`, 'gi'))

    return parts.map((part, index) => {
      const isMatch = part.toLowerCase() === searchKeyword.toLowerCase()
      return isMatch ? (
        <span key={index} className={styles.higlighted}>
          {part}
        </span>
      ) : (
        part
      )
    })
  }

  const productIcon = (productType: ProductType | undefined | null): React.ReactNode => {
    const iconMap: Record<ProductType, React.ReactNode> = {
      Mattresses: <ProductTypeMattress />,
      Bedding: <ProductTypeBedding />,
      'Foundations & Bases': <ProductTypeFoundation />,
      Furniture: <ProductTypeFurniture />,
      Pillows: <ProductTypePillow />
    }
    return productType ? iconMap[productType] : <></>
  }

  const handleAdd = (searchResultItem: ProductSearchResultItem) => {
    if (!searchResultItem) return
    setProductSearchResults((prev: ProductSearchResultItem[]) => [...prev, searchResultItem])
  }

  const isItemInSearchResults = (item: ProductSearchResultItem) => {
    return productSearchResults.some(
      (existingItem: ProductSearchResultItem) => existingItem.productSku === item.productSku
    )
  }

  return (
    <>
      {variant === 'redesigned' ? (
        searchInputNotEmpty && hasSearchResult && !isLoading ? (
          <div className={clsx(styles.redesignModalRow, styles.content)}>
            <Typography variant="paragraph">Products</Typography>
            <div className={styles.overflow}>
              {searchResults.map((searchResultItem: ProductSearchResultItem, index: number) => (
                <div key={searchResultItem.productSku} className={styles.listItem}>
                  <div className={styles.product}>
                    <div className={styles.searchIcon}>{productIcon(searchResultItem.productType)}</div>
                    <div>
                      <div>{getHighlightedName(searchResultItem.productName, searchTerm)}</div>
                      <Typography variant="paragraph">{searchResultItem.productSku}</Typography>
                    </div>
                  </div>
                  <div className={styles.actionButtons}>
                    {isItemInSearchResults(searchResultItem) ? (
                      <Button type="button" className={clsx(styles.btn, styles.buttonAdded)} disabled>
                        <CheckMark />
                        Added
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        className={clsx(styles.btn, styles.buttonAdd)}
                        onClick={() => handleAdd(searchResultItem)}
                      >
                        <PlusRedesign />
                        Add
                      </Button>
                    )}
                    <CopyButton displayText="" variant="redesigned" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : !isLoading && !searchInputNotEmpty ? (
          <div className={clsx(styles.redesignModalRow, styles.content)}>
            <Typography variant="paragraph">History</Typography>
            <div className={clsx(styles.redesignListRow, styles.overflow)} />
          </div>
        ) : !isLoading ? (
          <div className={clsx(styles.redesignModalRow, styles.overflow)}>
            <Typography variant="paragraph">Products</Typography>
            <Typography variant="paragraph" className={styles.noresult}>
              Not seeing a product? Please refine your search terms.
            </Typography>
          </div>
        ) : null
      ) : !searchInputNotEmpty ? null : (
        <div className={productSearchListContainer}>
          <Typography className={productSearchListTitle} variant="paragraph-sm">
            Products
          </Typography>
          <div className={productSearchList}>
            {isSearched ? (
              <>
                {searchResults.map((searchResultItem: ProductSearchResultItem, index: number) => (
                  <ProductSearchListItem
                    analyticsPrefixId={`${analyticsPrefixId}_result_pos${index}`}
                    key={searchResultItem.productSku}
                    productIndex={index}
                    {...searchResultItem}
                  />
                ))}
                {(!isLoading || hasSearchResult) && (
                  <p
                    data-testid="refine-search-helper-text"
                    className={`${productSearchNoResultMsg} ${productSearchinfoMsg}`}
                  >
                    Not seeing a product? Please refine your search terms.
                  </p>
                )}
              </>
            ) : (
              <p data-testid="no-result-helper-text" className={productSearchNoResultMsg}>
                No results for “{searchTerm}”. Please refine your search terms.
              </p>
            )}
          </div>
        </div>
      )}
    </>
  )
}
export default ProductSearchList
