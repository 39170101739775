export { detectUnifyCustomerChange, literal } from './utils'
export {
  MFRM_UNIFY_ACTIVE_CUSTOMER_DETAIL_KEY,
  MFRM_UNIFY_CART_ADDRESS_KEY,
  MFRM_UNIFY_CART_CUSTOMER_KEY,
  MFRM_UNIFY_CART_DETAIL_KEY,
  MFRM_UNIFY_CUSTOMER_DETAIL_KEY,
  MFRM_UNIFY_MM_ANSWERS_KEY,
  MFRM_UNIFY_MM_D365_BASKET_UUID,
  MFRM_UNIFY_MM_PENDING_ANSWERS_KEY,
  MFRM_UNIFY_MM_POSITION_KEY,
  MFRM_UNIFY_MM_PREVIEW_BANNER_KEY,
  MFRM_UNIFY_MM_QUIZ_STARTED,
  MFRM_UNIFY_MM_RECOMMENDATIONS_KEY,
  MFRM_UNIFY_MM_RECENT_SEARCHES_KEY,
  MFRM_UNIFY_NOTE_EDITED_DETAIL_KEY,
  MFRM_REST_LEAD_DETAILS_KEY,
  MFRM_REST_LEAD_SAVED_KEY,
  MFRM_REST_NOTE_DETAILS_KEY,
  MFRM_UNIFY_REST_TAB_NAME,
  MFRM_UNIFY_CARE_TAB_NAME,
  MFRM_UNIFY_CLIENTELING_TAB_NAME,
  MFRM_UNIFY_SE_DETAIL_KEY,
  MFRM_UNIFY_SE_SIGNATURES_KEY,
  MFRM_UNIFY_SESSION_INFO_KEY,
  MFRM_UNIFY_XRAY_EVENT_CATEGORY,
  MFRM_UNIFY_XRAY_FAVORITES_KEY,
  MFRM_UNIFY_XRAY_LAUNCH_EVENT,
  MFRM_UNIFY_XRAY_PATH,
  MFRM_UNIFY_XRAY_SELECTED_BRAND_KEY,
  MFRM_UNIFY_MODAL_EDITOR_STORE_KEY,
  MFRM_REST_ORIGINAL_LEAD_DETAILS_KEY,
  MFRM_REST_FILTER_STATUSES_KEY,
  MFRM_CARE_FILTER_KEY,
  MFRM_UNIFY_CLIENTELING_PRODUCT_SEARCH_ADDED
} from './consts/storageKeys'
export type {
  Brand,
  IAdjustableBase,
  IBrandStoreSelectedBrand,
  IFavorites,
  IMattress,
  IXRayPath
} from './interfaces/explore'
export { IStorageType } from './interfaces/storage'
export type { IStorage, IStorageRaw } from './interfaces/storage'
export { ICustomerDetailAction, INavigationAction, IProductSearchAction } from './interfaces/unify'
export type {
  IUnifySleepExpertDetail,
  IStorageCustomerDetails,
  IUnifySessionInfo,
  IUpdateMyStoreDTO
} from './interfaces/unify'
export { RemoteControlButton } from './interfaces/adjustable-bases'
export type {
  LightsConfig,
  MassageConfig,
  IAdjustableBaseState,
  IAdjustableBaseFeatures,
  IAdjustableBaseModelAnimations,
  IAdjustableBaseModel,
  IAdjustableBaseModelMaterials
} from './interfaces/adjustable-bases'
export { INavigationTab, IQuestionKey } from './interfaces/mattressmatcher'
export type {
  Answers,
  CustomerAnswers,
  IComfortRange,
  IMattressSize,
  IPillowHeight,
  IPriceRange,
  IQuizFlowMode,
  IUnifyMMPosition,
  IUsageFrequency,
  Reason
} from './interfaces/mattressmatcher'
export type { RequiredPick } from './interfaces/generic'

export type {
  Features,
  FormattedAnswers,
  RecommendationsData,
  SleepProfile,
  UpperCaseSleepProfile
} from './interfaces/recommendationsData'
