import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  isRedesign?: boolean
  [x: string]: unknown // for the rest property
}

const TrashCan: React.FunctionComponent<Props> = ({
  width: widthProp,
  height: heightProp,
  fillColor = '#2D2926',
  isRedesign = false,
  ...rest
}) => {
  const width = widthProp || (isRedesign ? 14 : 23)
  const height = heightProp || (isRedesign ? 18 : 26)
  if (isRedesign) {
    return (
      <svg width={width} height={height} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z"
          fill={fillColor}
        />
      </svg>
    )
  }
  return (
    <svg width={width} height={height} viewBox="0 0 23 26" {...rest}>
      <g fill={fillColor}>
        <path clipRule="evenodd" d="m4 25.5h15.09l2.01-23h-19.1zm14.16-1h-13.24l-1.87-21h16.9z" fillRule="evenodd" />
        <path d="m22.5 3.5h-22c-.276142 0-.5-.22386-.5-.5s.223858-.5.5-.5h22c.2761 0 .5.22386.5.5s-.2239.5-.5.5z" />
        <rect height="14.97" rx=".5" width="1" x="10.99" y="6.53003" />
        <rect height="14.97" rx=".5" width="1" x="7.09998" y="6.53003" />
        <rect height="14.97" rx=".5" width="1" x="14.88" y="6.53003" />
      </g>
      <path d="m8 .5h7" stroke="#000" strokeLinecap="round" />
    </svg>
  )
}

export default TrashCan
