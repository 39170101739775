import React, {
  FunctionComponent,
  KeyboardEvent,
  MutableRefObject,
  isValidElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import clsx from 'clsx'
import Close from '../Icons/Close'
import Fade from '../Fade'
import Portal from '../Portal'
import Typography from '../Typography'
import styles from './modal.module.scss'
import useOpenModal from '../utils/useOpenModal'
import trapFocus from '../utils/trapFocus'
import { CUSTOM_EVENT_SESSION_EXPIRATION } from '../SessionExpireWrapper/consts/unify'

interface Props {
  /** Show a backdrop */
  backdrop?: boolean
  /** sets opacity of backdrop */
  backdropOpacity?: number
  /** Override styles on backdrop */
  backdropClassName?: string
  /** Duration of backdrop fade */
  backdropDuration?: number
  /** Header title for the modal  */
  header?: string | React.ReactNode
  /** Subheader title for the modal */
  subheader?: string | React.ReactNode
  /** Class to pass to the modal center wrapper */
  contentClass?: string
  /** Whether or not the modal is open */
  isOpen?: boolean
  /** Callback function after the modal is hidden */
  onClose?: (onSessionExpiration?: boolean) => void | null
  /** Transition speed when the modal appears */
  duration?: number
  /** Color of close button svg  */
  closeButtonColor?: string
  /** Class to pass to the modal close button  */
  closeButtonClass?: string
  /** ID to pass to the modal close button  */
  closeButtonId?: string
  /** Child elements of the modal  */
  children?: React.ReactNode
  /** Class to pass to the children wrapper */
  childrenClass?: string
  /** id for modal */
  id?: string
  /** explicitly sets z-index of modal */
  zIndex?: number
  /** explicitly sets z-index of backdrop */
  backdropZIndex?: number
  /** style to indicate modal border setting */
  borderStyle?: 'round' | 'square' | 'redesign-round'
  /** ref for the modal */
  modalRef?: MutableRefObject<HTMLDivElement | null>
  /** applies dreamhub styles */
  dreamhub?: boolean
  /** apply static background (backdrop will not close on click or key event) */
  isStaticBackdrop?: boolean
  /** Use sparingly! This prop will override the close icon button */
  pauseClosing?: boolean
  isRedesign?: boolean
  [rest: string]: unknown // ...rest property
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const noopHandleKeys = (e: KeyboardEvent<HTMLDivElement>) =>
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  {}

const Modal: FunctionComponent<Props> = ({
  backdrop = true,
  backdropOpacity = 0.5,
  backdropClassName = '',
  backdropDuration = 50,
  header = '',
  subheader = '',
  contentClass = '',
  childrenClass = '',
  isOpen = false,
  onClose = null,
  duration = 100,
  children = null,
  closeButtonColor = '#2D2926',
  closeButtonClass = '',
  closeButtonId = undefined,
  id = '',
  zIndex = 1000,
  backdropZIndex = 950,
  borderStyle = 'round',
  modalRef = null,
  dreamhub,
  isStaticBackdrop = false,
  pauseClosing = false,
  isRedesign = false,
  ...rest
}) => {
  const [isShowing, setIsShowing] = useState(isOpen)

  const modalRefWrapper = useRef<HTMLDivElement | null>(modalRef ? modalRef.current : null)
  const closeBtnRef = useRef<HTMLDivElement>(null)
  const portalRef = useRef<HTMLDivElement>(null)

  useOpenModal({ isOpen, setIsShowing, closeBtnRef })
  const hideModal = useCallback(() => {
    if (onClose) {
      onClose()
    }
    setIsShowing(!isOpen)
  }, [isOpen, onClose])

  const handleKeys = (e: KeyboardEvent<HTMLDivElement>) => {
    const key = e.keyCode || e.which

    switch (key) {
      // Escape
      case 27: {
        hideModal()
        break
      }
      // tab
      case 9: {
        trapFocus(e, modalRefWrapper)
        break
      }
      default:
        break
    }
  }

  const modalClasses = clsx(
    styles.modal,
    isShowing && styles.active,
    dreamhub && styles.unify,
    styles[borderStyle],
    rest.className as string,
    isRedesign && styles['modal-redesign']
  )
  const modalContentClasses = clsx(styles['modal-content'], dreamhub && styles['modal-content-unify'], contentClass)
  const modalWrapperClasses = clsx(styles['modal-wrapper'], isShowing && styles.active)
  const modalChildrenClasses = clsx(
    styles['modal-children-wrapper'],
    dreamhub && styles['modal-children-wrapper-unify'],
    childrenClass
  )

  useEffect(() => {
    const closeModal = () => {
      onClose?.(false)
    }
    const expireModal = () => {
      onClose?.(true)
    }

    window.addEventListener('popstate', closeModal)
    window.addEventListener(CUSTOM_EVENT_SESSION_EXPIRATION, expireModal)
    return () => {
      window.removeEventListener('popstate', closeModal)
      window.removeEventListener(CUSTOM_EVENT_SESSION_EXPIRATION, expireModal)
    }
  }, [onClose])

  return (
    <Portal ref={portalRef}>
      <div className={modalWrapperClasses} style={{ zIndex }}>
        {backdrop && (
          <Fade
            className={clsx(styles['modal-backdrop'], isOpen && styles.backdrop, backdropClassName)}
            style={{ zIndex: backdropZIndex, opacity: backdropOpacity }}
            onClick={isStaticBackdrop ? noop : hideModal}
            onKeyDown={isStaticBackdrop ? noopHandleKeys : handleKeys}
            duration={backdropDuration}
            in={isOpen && !!backdrop}
            opacity={backdropOpacity}
          />
        )}
        <div
          role="dialog"
          aria-modal="true"
          onKeyDown={handleKeys}
          ref={modalRefWrapper}
          id={id}
          {...rest}
          className={modalClasses}
        >
          {isRedesign ? (
            <>
              <div className={clsx(styles['modal-redesign-header-container'])}>
                <div className={clsx(styles['modal-redesign-header'])}>
                  {(isValidElement(header) && header) ||
                    (header && header !== '' && (
                      <Typography className={clsx(styles['modal-header'])} variant="h4">
                        {header}
                      </Typography>
                    ))}
                  {(isValidElement(subheader) && subheader) ||
                    (subheader && subheader !== '' && (
                      <Typography className={clsx(styles['modal-subheader'])} variant="paragraph">
                        {subheader}
                      </Typography>
                    ))}
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={pauseClosing ? noop : hideModal}
                  className={clsx(styles['redesign-close'])}
                  aria-label="Close Modal"
                  ref={closeBtnRef}
                  id={closeButtonId ?? `close-modal-${id}`}
                >
                  <Close width="14px" height="14px" stroke="#B3B5B7" strokeWidth="2" />
                </div>
              </div>
              <div className={clsx(styles['redesign-body-wrapper'], contentClass)}>{children}</div>
            </>
          ) : (
            <>
              <div
                role="button"
                tabIndex={0}
                onClick={pauseClosing ? noop : hideModal}
                className={styles.close}
                aria-label="Close Modal"
                ref={closeBtnRef}
                id={closeButtonId ?? `close-modal-${id}`}
              >
                <span className={clsx(styles['close-icon-wrapper'], closeButtonClass)} aria-hidden="true">
                  <Close width="10px" height="10px" stroke={closeButtonColor} strokeWidth="2" />
                </span>
              </div>
              <div className={modalContentClasses}>
                {(isValidElement(header) && header) ||
                  (header && header !== '' && (
                    <Typography className={clsx(styles['modal-header'])} variant="h4" align="center">
                      {header}
                    </Typography>
                  ))}
                {(isValidElement(subheader) && subheader) ||
                  (subheader && subheader !== '' && (
                    <Typography className={clsx(styles['modal-subheader'])} variant="paragraph" align="center">
                      {subheader}
                    </Typography>
                  ))}
                <div className={modalChildrenClasses}>{children}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </Portal>
  )
}

export default Modal
