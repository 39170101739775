import { ProductType } from '../../consts'

export const getAbortController = () => {
  let ac: AbortController | null = null

  return () => {
    if (ac) {
      ac.abort()
    }
    ac = new AbortController()
    return ac
  }
}

export const getDebouncingFunction = (cb: (value: string, filterCategories: ProductType[]) => void) => {
  let timeoutId: NodeJS.Timeout | null = null

  return (value: string, filterCategories: ProductType[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(() => {
      cb(value, filterCategories)
    }, 500)
  }
}
