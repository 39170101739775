import { ChangeEvent, createContext, MouseEvent } from 'react'
import { ProductSearchResults } from '../schema'
import { ProductType } from '../../consts'

interface IProductSearch {
  searchResults: ProductSearchResults
  searchTerm: string
  isLoading: boolean
  handleSubmit: (e?: MouseEvent<HTMLButtonElement>) => void
  handleClick: (productSku: string, productName: string, productIndex: number) => void
  handleReset: () => void
  handleKeyDown: (e: KeyboardEvent) => void
  handleSearchTerm: (e: ChangeEvent<HTMLInputElement>) => void
  onListItemClick: (productSku: string, searchTerm: string, productIndex: number) => void
  handleChipClick: (label: string) => void
  selectedChips: ProductType[]
}

const DEFAULT_SEARCH_RESULT_CTX: IProductSearch = {
  isLoading: false,
  searchResults: [],
  searchTerm: '',
  handleClick: () => {},
  handleSubmit: () => {},
  handleReset: () => {},
  handleKeyDown: () => {},
  handleSearchTerm: () => {},
  onListItemClick: () => {},
  handleChipClick: () => {},
  selectedChips: []
}

const ProductSearchPopupCtx = createContext(DEFAULT_SEARCH_RESULT_CTX)
ProductSearchPopupCtx.displayName = 'ProductSearchPopup'

export { DEFAULT_SEARCH_RESULT_CTX, ProductSearchPopupCtx }
