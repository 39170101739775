import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fill?: string
}

const ProductTypeMattress: React.FunctionComponent<Props> = ({ width = '16', height = '16', fill = '#626366' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33203 3.15143C1.33203 2.14728 2.14606 1.33325 3.15021 1.33325H12.8472C13.8513 1.33325 14.6654 2.14728 14.6654 3.15143V12.8484C14.6654 13.8526 13.8513 14.6666 12.8472 14.6666H3.15021C2.14606 14.6666 1.33203 13.8526 1.33203 12.8484V3.15143ZM3.15021 2.54537C2.8155 2.54537 2.54415 2.81672 2.54415 3.15143V12.8484C2.54415 13.1831 2.8155 13.4545 3.15021 13.4545H12.8472C13.1819 13.4545 13.4532 13.1831 13.4532 12.8484V3.15143C13.4532 2.81672 13.1819 2.54537 12.8472 2.54537H3.15021Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33203 12.8484C1.33203 11.8442 2.14606 11.0302 3.15021 11.0302H12.8472C13.8513 11.0302 14.6654 11.8442 14.6654 12.8484C14.6654 13.8526 13.8513 14.6666 12.8472 14.6666H3.15021C2.14606 14.6666 1.33203 13.8526 1.33203 12.8484ZM3.15021 12.2423C2.8155 12.2423 2.54415 12.5137 2.54415 12.8484C2.54415 13.1831 2.8155 13.4545 3.15021 13.4545H12.8472C13.1819 13.4545 13.4532 13.1831 13.4532 12.8484C13.4532 12.5137 13.1819 12.2423 12.8472 12.2423H3.15021Z"
      fill={fill}
    />
    <path
      d="M4.36233 4.06052C4.36233 4.22788 4.22666 4.36355 4.0593 4.36355C3.89195 4.36355 3.75627 4.22788 3.75627 4.06052C3.75627 3.89317 3.89195 3.75749 4.0593 3.75749C4.22666 3.75749 4.36233 3.89317 4.36233 4.06052Z"
      fill={fill}
    />
    <path
      d="M6.9886 4.06052C6.9886 4.22788 6.85293 4.36355 6.68557 4.36355C6.51821 4.36355 6.38254 4.22788 6.38254 4.06052C6.38254 3.89317 6.51821 3.75749 6.68557 3.75749C6.85293 3.75749 6.9886 3.89317 6.9886 4.06052Z"
      fill={fill}
    />
    <path
      d="M9.61486 4.06052C9.61486 4.22788 9.47919 4.36355 9.31183 4.36355C9.14447 4.36355 9.0088 4.22788 9.0088 4.06052C9.0088 3.89317 9.14447 3.75749 9.31183 3.75749C9.47919 3.75749 9.61486 3.89317 9.61486 4.06052Z"
      fill={fill}
    />
    <path
      d="M12.2411 4.06052C12.2411 4.22788 12.1055 4.36355 11.9381 4.36355C11.7707 4.36355 11.6351 4.22788 11.6351 4.06052C11.6351 3.89317 11.7707 3.75749 11.9381 3.75749C12.1055 3.75749 12.2411 3.89317 12.2411 4.06052Z"
      fill={fill}
    />
    <path
      d="M4.36233 6.7878C4.36233 6.95516 4.22666 7.09083 4.0593 7.09083C3.89195 7.09083 3.75627 6.95516 3.75627 6.7878C3.75627 6.62044 3.89195 6.48477 4.0593 6.48477C4.22666 6.48477 4.36233 6.62044 4.36233 6.7878Z"
      fill={fill}
    />
    <path
      d="M6.9886 6.7878C6.9886 6.95516 6.85293 7.09083 6.68557 7.09083C6.51821 7.09083 6.38254 6.95516 6.38254 6.7878C6.38254 6.62044 6.51821 6.48477 6.68557 6.48477C6.85293 6.48477 6.9886 6.62044 6.9886 6.7878Z"
      fill={fill}
    />
    <path
      d="M9.61486 6.7878C9.61486 6.95516 9.47919 7.09083 9.31183 7.09083C9.14447 7.09083 9.0088 6.95516 9.0088 6.7878C9.0088 6.62044 9.14447 6.48477 9.31183 6.48477C9.47919 6.48477 9.61486 6.62044 9.61486 6.7878Z"
      fill={fill}
    />
    <path
      d="M12.2411 6.7878C12.2411 6.95516 12.1055 7.09083 11.9381 7.09083C11.7707 7.09083 11.6351 6.95516 11.6351 6.7878C11.6351 6.62044 11.7707 6.48477 11.9381 6.48477C12.1055 6.48477 12.2411 6.62044 12.2411 6.7878Z"
      fill={fill}
    />
    <path
      d="M4.36233 9.51507C4.36233 9.68243 4.22666 9.8181 4.0593 9.8181C3.89195 9.8181 3.75627 9.68243 3.75627 9.51507C3.75627 9.34771 3.89195 9.21204 4.0593 9.21204C4.22666 9.21204 4.36233 9.34771 4.36233 9.51507Z"
      fill={fill}
    />
    <path
      d="M6.9886 9.51507C6.9886 9.68243 6.85293 9.8181 6.68557 9.8181C6.51821 9.8181 6.38254 9.68243 6.38254 9.51507C6.38254 9.34771 6.51821 9.21204 6.68557 9.21204C6.85293 9.21204 6.9886 9.34771 6.9886 9.51507Z"
      fill={fill}
    />
    <path
      d="M9.61486 9.51507C9.61486 9.68243 9.47919 9.8181 9.31183 9.8181C9.14447 9.8181 9.0088 9.68243 9.0088 9.51507C9.0088 9.34771 9.14447 9.21204 9.31183 9.21204C9.47919 9.21204 9.61486 9.34771 9.61486 9.51507Z"
      fill={fill}
    />
    <path
      d="M12.2411 9.51507C12.2411 9.68243 12.1055 9.8181 11.9381 9.8181C11.7707 9.8181 11.6351 9.68243 11.6351 9.51507C11.6351 9.34771 11.7707 9.21204 11.9381 9.21204C12.1055 9.21204 12.2411 9.34771 12.2411 9.51507Z"
      fill={fill}
    />
  </svg>
)

export default ProductTypeMattress
