import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { ProductSearchPopupCtx } from '.'
import { ProductSearchResults } from '../schema'
import { IProductSearchProviderProps } from '../interfaces/productSearchModal'
import { DEFAULT_RECENT_PRODUCT_SEARCHES } from '../../RecentProductSearches/context'
import { MFRM_UNIFY_MM_RECENT_SEARCHES_KEY } from '../../conventions/consts/storageKeys'
import { IStorageType } from '../../conventions'
import { useStorageReducer } from '../../useStorage'
import { recentProductSearchesReducer } from '../../RecentProductSearches'
import { RECENT_PRODUCT_SEARCHES_ACTION_TYPES } from '../../RecentProductSearches/interfaces'
import { getAbortController, getDebouncingFunction } from '../utils'
import { ProductType } from '../../consts'

export const ProductSearchProvider: FunctionComponent<IProductSearchProviderProps> = ({
  children,
  isModalOpen = false,
  enableFakeData = false,
  fakeSearchData = [],
  onListItemClick,
  onSuccessFullSearch,
  getProductSearchResult
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dispatch] = useStorageReducer(recentProductSearchesReducer, {
    value: DEFAULT_RECENT_PRODUCT_SEARCHES,
    key: MFRM_UNIFY_MM_RECENT_SEARCHES_KEY,
    storageType: IStorageType.Session
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedChips, setSelectedChips] = useState<ProductType[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchResults, setSearchResults] = useState<ProductSearchResults>([])

  useEffect(() => {
    if (!isModalOpen) {
      setSearchTerm('')
    }
  }, [isModalOpen])

  const productSearchAc = useMemo(() => getAbortController(), [])

  const handleSubmit = useCallback(
    async (value: string, filterCategories: ProductType[]) => {
      const lowerCaseString = value.trim().toLocaleLowerCase()
      if (lowerCaseString?.length > 0) {
        const ac = productSearchAc()
        setIsLoading(true)
        try {
          if (enableFakeData) {
            setSearchResults(fakeSearchData?.filter(({ productName }) => productName?.includes(lowerCaseString)))
          } else {
            const data = await getProductSearchResult(
              { searchTerm: lowerCaseString, filterCategories },
              { signal: ac.signal }
            )
            setSearchResults(data ?? [])
            onSuccessFullSearch?.(data)
          }
        } catch (error) {
          console.error(error)
        }
      }
      setIsLoading(false)
    },
    [enableFakeData, fakeSearchData, getProductSearchResult, productSearchAc, onSuccessFullSearch]
  )

  const debouncedHandleSubmit = useMemo(() => getDebouncingFunction(handleSubmit), [handleSubmit])

  const handleSubmitBtnClick = useCallback(() => {
    handleSubmit(searchTerm, selectedChips)
  }, [handleSubmit, searchTerm, selectedChips])

  const handleReset = useCallback(() => {
    setSearchTerm('')
    setSearchResults([])
  }, [])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => (e.code === 'Enter' ? handleSubmit(searchTerm, selectedChips) : false),
    [handleSubmit, searchTerm, selectedChips]
  )

  const handleChipClick = (label: string) => {
    setSelectedChips((prevSelected) => {
      const updatedSelected = prevSelected.includes(label as ProductType)
        ? prevSelected.filter((chip) => chip !== label)
        : [...prevSelected, label as ProductType]

      handleSubmit(searchTerm, updatedSelected)
      return updatedSelected
    })
  }

  const handleSearchTerm = useCallback(
    ({ target: { value }, type }: ChangeEvent<HTMLInputElement>) => {
      if (type === 'blur') {
        return
      }

      if (value.trim().length > 0) {
        setSearchResults([])
        setIsLoading(true)
      }

      debouncedHandleSubmit(value, selectedChips)
      setSearchTerm(value)
    },
    [debouncedHandleSubmit, selectedChips]
  )

  const handleClick = useCallback(
    (productSku = '', productName = '', productIndex = 0) => {
      // Add to recent searches.
      dispatch({ type: RECENT_PRODUCT_SEARCHES_ACTION_TYPES.ADD, payload: { productName, productSku } })
      // Return the selected product sku to callback.
      onListItemClick?.(productSku, searchTerm, productIndex)
    },
    [dispatch, onListItemClick, searchTerm]
  )

  // Memoized value
  const value = useMemo(
    () => ({
      isLoading,
      searchTerm,
      searchResults,
      handleClick,
      handleKeyDown,
      handleReset,
      handleSearchTerm,
      handleSubmit: handleSubmitBtnClick,
      onListItemClick,
      handleChipClick,
      selectedChips
    }),
    [
      isLoading,
      searchTerm,
      searchResults,
      handleClick,
      handleKeyDown,
      handleReset,
      handleSearchTerm,
      handleSubmitBtnClick,
      onListItemClick,
      handleChipClick,
      selectedChips
    ]
  )

  return <ProductSearchPopupCtx.Provider value={value}>{children}</ProductSearchPopupCtx.Provider>
}
