import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fill?: string
}

const CheckMark: React.FunctionComponent<Props> = ({ width = '24', height = '24', fill = 'white' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.7L19.4 5.3L8.79995 15.9Z" fill={fill} />
  </svg>
)

export default CheckMark
