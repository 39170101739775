import * as z from 'zod'

/** Schemas */
const errorSchema = z.object({ message: z.string() })

const queryParamsSchema = z.object({
  'log.response': z.string(),
  'module.exclude': z.string(),
  'alternate.op': z.string(),
  'q.op': z.string(),
  variants: z.string(),
  'popularProducts.filter': z.string(),
  'f.categoryPath.facet.limit': z.string(),
  'promotion.version': z.string(),
  'req.rm.promotionEngine': z.string(),
  'f.categoryPath.nameId': z.string(),
  'inFields.count': z.string(),
  'topQueries.count': z.string(),
  'original.q': z.string(),
  'unx.cache.maxAgeInSecs': z.string(),
  'popularProducts.count': z.string(),
  'req.rm.asterix': z.string(),
  enableTaxonomy: z.string(),
  'variants.relevant': z.string(),
  version: z.string(),
  q: z.string(),
  'f.categoryPath.max.depth': z.string(),
  'keywordSuggestions.count': z.string(),
  'facet.multilevel': z.string(),
  'user.behaviour': z.string(),
  enablePopularity: z.string(),
  promotion: z.string()
})

const searchMetaDataSchema = z.object({
  status: z.number(),
  queryTime: z.number(),
  queryParams: queryParamsSchema
})

const variantSchema = z.object({
  v_ItemId: z.string().optional(),
  v_MasterProductId: z.number().optional(),
  v_InventoryDimensionId: z.string().optional(),
  v_AdjustedPrice: z.number().optional(),
  v_BasePrice: z.number().optional(),
  v_Price: z.number().optional(),
  v_Color: z.string().optional(),
  v_Style: z.string().optional(),
  v_Size: z.string().optional(),
  v_Configuration: z.string().optional(),
  v_ColorId: z.string().optional(),
  v_StyleId: z.string().optional(),
  v_SizeId: z.string().optional(),
  v_ConfigId: z.string().optional(),
  v_VariantId: z.string().optional(),
  variantId: z.string().optional(),
  v_DistinctProductVariantId: z.number().optional(),
  v_ProductNumber: z.string().optional(),
  v_ChannelId: z.number().optional(),
  v_DefaultCurrencyCode: z.string().optional(),
  v_Attr_5637177579: z.string().optional(),
  v_Attr_5637177578: z.string().optional(),
  v_Attr_5637177576: z.string().optional(),
  v_Attr_5637171576: z.string().optional(),
  v_Attr_5637168577: z.string().optional(),
  v_Attr_5637164076: z.string().optional(),
  v_Attr_5637164077: z.string().optional(),
  v_Attr_5637159576: z.string().optional(),
  v_Attr_5637157326: z.string().optional(),
  v_Attr_5637144596: z.string().optional(),
  v_Attr_5637144595: z.string().optional(),
  v_Attr_5637149096: z.string().optional(),
  v_Attr_5637149136: z.string().optional(),
  v_Attr_5637149137: z.string().optional(),
  v_Attr_5637149135: z.string().optional(),
  v_Attr_5637149125: z.string().optional(),
  v_Attr_5637149126: z.string().optional(),
  v_Attr_5637149153: z.string().optional(),
  v_Attr_5637145355: z.string().optional(),
  v_Attr_5637149145: z.string().optional(),
  v_Attr_5637149144: z.string().optional(),
  v_Attr_5637149143: z.string().optional(),
  v_Attr_5637149142: z.string().optional(),
  v_Attr_5637149141: z.string().optional(),
  v_Attr_5637149134: z.string().optional(),
  v_Attr_5637149133: z.string().optional(),
  v_Attr_5637149132: z.string().optional(),
  v_Attr_5637149129: z.string().optional(),
  v_Attr_5637149122: z.string().optional(),
  v_Attr_5637149095: z.string().optional(),
  v_Attr_5637149094: z.string().optional(),
  v_Attr_5637149093: z.string().optional(),
  v_Attr_5637149092: z.string().optional(),
  v_Attr_5637149091: z.string().optional(),
  v_Attr_5637149090: z.string().optional(),
  v_Attr_5637149089: z.string().optional(),
  v_Attr_5637149088: z.string().optional(),
  v_Attr_5637149087: z.string().optional(),
  v_Attr_5637149086: z.string().optional(),
  v_Attr_5637149085: z.string().optional(),
  v_Attr_5637149083: z.string().optional(),
  v_Attr_5637149082: z.string().optional(),
  v_Attr_5637149081: z.string().optional(),
  v_Attr_5637149080: z.string().optional(),
  v_Attr_5637149079: z.string().optional(),
  v_Attr_5637149078: z.string().optional(),
  v_Attr_5637149077: z.string().optional(),
  v_Attr_5637149076: z.string().optional(),
  v_Attr_5637149138: z.string().optional(),
  v_Attr_5637149128: z.string().optional(),
  v_Attr_5637149151: z.string().optional(),
  v_Attr_5637149147: z.string().optional(),
  v_Attr_5637149148: z.string().optional(),
  v_Attr_5637176826: z.string().optional(),
  v_Attr_6: z.string().optional(),
  v_Attr_1: z.string().optional(),
  v_imageUrl: z.array(z.string()).optional(),
  v_mfi_ItemId: z.string().optional(),
  v_mfi_VariantId: z.string().optional(),
  v_CustomerContextualPrice: z.number().optional(),
  v_DiscountAmount: z.number().optional(),
  v_PhysicalAvailableQuantity: z.number().optional(),
  v_PhysicalAvailableInventoryLevelCode: z.string().optional(),
  v_PhysicalAvailableInventoryLevelLabel: z.string().optional(),
  parent_unbxd: z.boolean().optional(),
  parentId: z.string().optional(),
  timeStamp_unbxd: z.number().optional(),
  unbxdFeedId: z.string().optional(),
  RecordId: z.number().optional(),
  ProductName: z.string().optional(),
  ProductNumber: z.string().optional(),
  IsMasterProduct: z.string().optional(),
  IsKit: z.string().optional(),
  ItemId: z.string().optional(),
  HasLinkedProducts: z.string().optional(),
  Locale: z.string().optional(),
  IsRemote: z.string().optional(),
  BasePrice: z.number().optional(),
  Price: z.number().optional(),
  AdjustedPrice: z.number().optional(),
  PrimaryCategoryId: z.number().optional(),
  CategoryIds: z.array(z.number()).optional(),
  SearchName: z.string().optional(),
  ChannelId: z.number().optional(),
  MasterProductId: z.number().optional(),
  DocumentId: z.string().optional(),
  ProductRecordId: z.number().optional(),
  Name: z.string().optional(),
  DefaultCurrencyCode: z.string().optional(),
  Keywords: z.array(z.string()).optional(),
  DefaultUnitOfMeasure: z.string().optional(),
  Categories: z.array(z.string()).optional(),
  Attr_5637177579: z.array(z.string()).optional(),
  Attr_5637177578: z.array(z.string()).optional(),
  Attr_5637177576: z.array(z.string()).optional(),
  Attr_5637171576: z.string().optional(),
  Attr_5637168577: z.string().optional(),
  Attr_5637164076: z.string().optional(),
  Attr_5637164077: z.string().optional(),
  Attr_5637159576: z.string().optional(),
  Attr_5637157326: z.string().optional(),
  Attr_5637149096: z.string().optional(),
  Attr_5637149136: z.string().optional(),
  Attr_5637149137: z.string().optional(),
  Attr_5637149135: z.string().optional(),
  Attr_5637149125: z.string().optional(),
  Attr_5637149126: z.string().optional(),
  Attr_5637149153: z.string().optional(),
  Attr_5637145355: z.array(z.string()).optional(),
  Attr_5637149145: z.string().optional(),
  Attr_5637149144: z.string().optional(),
  Attr_5637149143: z.string().optional(),
  Attr_5637149142: z.string().optional(),
  Attr_5637149141: z.string().optional(),
  Attr_5637149134: z.string().optional(),
  Attr_5637149133: z.string().optional(),
  Attr_5637149132: z.string().optional(),
  Attr_5637149129: z.array(z.string()).optional(),
  Attr_5637149122: z.string().optional(),
  Attr_5637149095: z.string().optional(),
  Attr_5637149094: z.string().optional(),
  Attr_5637149093: z.string().optional(),
  Attr_5637149092: z.string().optional(),
  Attr_5637149091: z.array(z.string()).optional(),
  Attr_5637149090: z.string().optional(),
  Attr_5637149089: z.string().optional(),
  Attr_5637149088: z.string().optional(),
  Attr_5637149087: z.string().optional(),
  Attr_5637149086: z.string().optional(),
  Attr_5637149085: z.string().optional(),
  Attr_5637149083: z.string().optional(),
  Attr_5637149082: z.string().optional(),
  Attr_5637149081: z.string().optional(),
  Attr_5637149080: z.string().optional(),
  Attr_5637149079: z.string().optional(),
  Attr_5637149078: z.string().optional(),
  Attr_5637149077: z.string().optional(),
  Attr_5637149076: z.string().optional(),
  Attr_5637149138: z.string().optional(),
  Attr_5637149128: z.string().optional(),
  Attr_5637149151: z.string().optional(),
  Attr_5637149147: z.string().optional(),
  Attr_5637149148: z.string().optional(),
  Attr_6: z.string().optional(),
  Attr_1: z.string().optional(),
  Attr_5637149160: z.string().optional(),
  Attr_5637149167: z.string().optional(),
  Images: z.string().optional(),
  PrimaryImageUrl: z.string().optional(),
  CatalogId: z.number().optional(),
  Attr_5637144596: z.array(z.string()).optional(),
  Attr_5637144596_DimensionIdMapping: z.array(z.string()).optional(),
  Attr_5637144595: z.array(z.string()).optional(),
  Attr_5637144595_DimensionIdMapping: z.array(z.string()).optional(),
  mfi_ItemId: z.string().optional(),
  recs_color_vid_mapping: z.array(z.string()).optional(),
  Unbxd_DefaultVariantID: z.string().optional(),
  Unbxd_DefaultVariantShippingInformation: z.string().optional(),
  Unbxd_DefaultVariantRetailVariantId: z.string().optional(),
  imageUrl: z.array(z.string()).optional(),
  complete_imageUrl: z.string().optional(),
  AverageRatingOverall: z.number().optional(),
  AverageRatingCountOverall: z.number().optional(),
  CustomerContextualPrice: z.number().optional(),
  DiscountAmount: z.number().optional(),
  lowest_variant_price: z.number().optional(),
  lowest_variant_adjustedPrice: z.number().optional(),
  lowest_variant_recordId: z.string().optional(),
  Assortments1_From: z.coerce.date().optional(),
  Assortments1_To: z.coerce.date().optional(),
  PhysicalAvailableQuantity: z.number().optional(),
  tryinstore: z.array(z.string()).optional(),
  storeSaleQty: z.number().optional(),
  categoryPathId1: z.array(z.string()).optional(),
  categoryPathId2: z.array(z.string()).optional(),
  categoryPathId3: z.array(z.string()).optional(),
  categoryPath1: z.array(z.string()).optional(),
  categoryPath2: z.array(z.string()).optional(),
  categoryPath3: z.array(z.string()).optional(),
  categoryPath1_fq: z.array(z.string()).optional(),
  categoryPath2_fq: z.array(z.string()).optional(),
  categoryPath3_fq: z.array(z.string()).optional(),
  categoryPath1_catMap: z.array(z.string()).optional(),
  categoryPath2_catMap: z.array(z.string()).optional(),
  categoryPath3_catMap: z.array(z.string()).optional(),
  categoryPath: z.array(z.string()).optional(),
  u_categoryPathId: z.array(z.string()).optional(),
  ItemId_unbxdaq: z.array(z.string()).optional(),
  autosuggest: z.string().optional(),
  autosuggest_unstemmed: z.string().optional(),
  Attr_5637144596_unbxdaq: z.array(z.string()).optional(),
  doctype: z.string().optional(),
  Attr_5637145355_unbxdaq: z.array(z.string()).optional(),
  Name_unbxdaq: z.array(z.string()).optional(),
  syn_unx_auto: z.array(z.string()).optional(),
  syn_unx_auto_unstemmed: z.array(z.string()).optional(),
  CURRENCY_num_unx_dm: z.array(z.number()).optional(),
  _root_: z.string().optional(),
  score: z.number().optional(),
  vId: z.string().optional()
})

const productSchema = z.object({
  AdjustedPrice: z.union([z.number(), z.null()]).optional(),
  Assortments1_From: z.union([z.coerce.date(), z.null()]).optional(),
  Assortments1_To: z.union([z.coerce.date(), z.null()]).optional(),
  Attr_1: z.union([z.null(), z.string()]).optional(),
  Attr_5637144595: z.union([z.array(z.string()), z.null()]).optional(),
  Attr_5637144595_DimensionIdMapping: z.union([z.array(z.string()), z.null()]).optional(),
  Attr_5637144596: z.union([z.array(z.string()), z.null()]).optional(),
  Attr_5637144596_DimensionIdMapping: z.union([z.array(z.string()), z.null()]).optional(),
  Attr_5637144596_unbxdaq: z.union([z.array(z.string()), z.null()]).optional(),
  Attr_5637145355: z.union([z.array(z.string()), z.null()]).optional(),
  Attr_5637145355_unbxdaq: z.union([z.array(z.string()), z.null()]).optional(),
  Attr_5637149076: z.union([z.null(), z.string()]).optional(),
  Attr_5637149077: z.union([z.null(), z.string()]).optional(),
  Attr_5637149078: z.union([z.null(), z.string()]).optional(),
  Attr_5637149079: z.union([z.null(), z.string()]).optional(),
  Attr_5637149080: z.union([z.null(), z.string()]).optional(),
  Attr_5637149081: z.union([z.null(), z.string()]).optional(),
  Attr_5637149082: z.union([z.null(), z.string()]).optional(),
  Attr_5637149083: z.union([z.null(), z.string()]).optional(),
  Attr_5637149085: z.union([z.null(), z.string()]).optional(),
  Attr_5637149086: z.union([z.null(), z.string()]).optional(),
  Attr_5637149087: z.union([z.null(), z.string()]).optional(),
  Attr_5637149088: z.union([z.null(), z.string()]).optional(),
  Attr_5637149089: z.union([z.null(), z.string()]).optional(),
  Attr_5637149090: z.union([z.null(), z.string()]).optional(),
  Attr_5637149091: z.union([z.array(z.string()), z.null()]).optional(),
  Attr_5637149092: z.union([z.null(), z.string()]).optional(),
  Attr_5637149093: z.union([z.null(), z.string()]).optional(),
  Attr_5637149094: z.union([z.null(), z.string()]).optional(),
  Attr_5637149095: z.union([z.null(), z.string()]).optional(),
  Attr_5637149096: z.union([z.null(), z.string()]).optional(),
  Attr_5637149122: z.union([z.null(), z.string()]).optional(),
  Attr_5637149125: z.union([z.null(), z.string()]).optional(),
  Attr_5637149126: z.union([z.null(), z.string()]).optional(),
  Attr_5637149128: z.union([z.null(), z.string()]).optional(),
  Attr_5637149129: z.union([z.array(z.string()), z.null()]).optional(),
  Attr_5637149132: z.union([z.null(), z.string()]).optional(),
  Attr_5637149133: z.union([z.null(), z.string()]).optional(),
  Attr_5637149134: z.union([z.null(), z.string()]).optional(),
  Attr_5637149135: z.union([z.null(), z.string()]).optional(),
  Attr_5637149136: z.union([z.null(), z.string()]).optional(),
  Attr_5637149137: z.union([z.null(), z.string()]).optional(),
  Attr_5637149138: z.union([z.null(), z.string()]).optional(),
  Attr_5637149141: z.union([z.null(), z.string()]).optional(),
  Attr_5637149142: z.union([z.null(), z.string()]).optional(),
  Attr_5637149143: z.union([z.null(), z.string()]).optional(),
  Attr_5637149144: z.union([z.null(), z.string()]).optional(),
  Attr_5637149145: z.union([z.null(), z.string()]).optional(),
  Attr_5637149147: z.union([z.null(), z.string()]).optional(),
  Attr_5637149148: z.union([z.null(), z.string()]).optional(),
  Attr_5637149151: z.union([z.null(), z.string()]).optional(),
  Attr_5637149153: z.union([z.null(), z.string()]).optional(),
  Attr_5637149160: z.union([z.null(), z.string()]).optional(),
  Attr_5637149167: z.union([z.null(), z.string()]).optional(),
  Attr_5637157326: z.union([z.null(), z.string()]).optional(),
  Attr_5637159576: z.union([z.null(), z.string()]).optional(),
  Attr_5637164076: z.union([z.null(), z.string()]).optional(),
  Attr_5637164077: z.union([z.null(), z.string()]).optional(),
  Attr_5637168577: z.union([z.null(), z.string()]).optional(),
  Attr_5637171576: z.union([z.null(), z.string()]).optional(),
  Attr_5637177576: z.union([z.array(z.string()), z.null()]).optional(),
  Attr_5637177578: z.union([z.array(z.string()), z.null()]).optional(),
  Attr_5637177579: z.union([z.array(z.string()), z.null()]).optional(),
  Attr_6: z.union([z.null(), z.string()]).optional(),
  AverageRatingCountOverall: z.union([z.number(), z.null()]).optional(),
  AverageRatingOverall: z.union([z.number(), z.null()]).optional(),
  BasePrice: z.union([z.number(), z.null()]).optional(),
  CURRENCY_num_unx_dm: z.union([z.array(z.number()), z.null()]).optional(),
  CatalogId: z.union([z.number(), z.null()]).optional(),
  Categories: z.union([z.array(z.string()), z.null()]).optional(),
  CategoryIds: z.union([z.array(z.number()), z.null()]).optional(),
  ChannelId: z.union([z.number(), z.null()]).optional(),
  CustomerContextualPrice: z.union([z.number(), z.null()]).optional(),
  DefaultCurrencyCode: z.union([z.null(), z.string()]).optional(),
  DefaultUnitOfMeasure: z.union([z.null(), z.string()]).optional(),
  DiscountAmount: z.union([z.number(), z.null()]).optional(),
  DocumentId: z.union([z.null(), z.string()]).optional(),
  HasLinkedProducts: z.union([z.null(), z.string()]).optional(),
  Images: z.union([z.null(), z.string()]).optional(),
  IsKit: z.union([z.null(), z.string()]).optional(),
  IsMasterProduct: z.union([z.null(), z.string()]).optional(),
  IsRemote: z.union([z.null(), z.string()]).optional(),
  ItemId: z.union([z.null(), z.string()]).optional(),
  ItemId_unbxdaq: z.union([z.array(z.string()), z.null()]).optional(),
  Keywords: z.union([z.array(z.string()), z.null()]).optional(),
  Locale: z.union([z.null(), z.string()]).optional(),
  MasterProductId: z.union([z.number(), z.null()]).optional(),
  Name: z.union([z.null(), z.string()]).optional(),
  Name_unbxdaq: z.union([z.array(z.string()), z.null()]).optional(),
  PhysicalAvailableQuantity: z.union([z.number(), z.null()]).optional(),
  Price: z.union([z.number(), z.null()]).optional(),
  PrimaryCategoryId: z.union([z.number(), z.null()]).optional(),
  PrimaryImageUrl: z.union([z.null(), z.string()]).optional(),
  ProductName: z.union([z.null(), z.string()]).optional(),
  ProductNumber: z.union([z.null(), z.string()]).optional(),
  ProductRecordId: z.union([z.number(), z.null()]).optional(),
  RecordId: z.union([z.number(), z.null()]).optional(),
  SearchName: z.union([z.null(), z.string()]).optional(),
  Unbxd_DefaultVariantID: z.union([z.null(), z.string()]).optional(),
  Unbxd_DefaultVariantRetailVariantId: z.union([z.null(), z.string()]).optional(),
  Unbxd_DefaultVariantShippingInformation: z.union([z.null(), z.string()]).optional(),
  autosuggest: z.string(),
  autosuggest_unstemmed: z.string(),
  categoryPath: z.union([z.array(z.string()), z.null()]).optional(),
  categoryPath1: z.union([z.array(z.string()), z.null()]).optional(),
  categoryPath1_catMap: z.union([z.array(z.string()), z.null()]).optional(),
  categoryPath1_fq: z.union([z.array(z.string()), z.null()]).optional(),
  categoryPath2: z.union([z.array(z.string()), z.null()]).optional(),
  categoryPath2_catMap: z.union([z.array(z.string()), z.null()]).optional(),
  categoryPath2_fq: z.union([z.array(z.string()), z.null()]).optional(),
  categoryPath3: z.union([z.array(z.string()), z.null()]).optional(),
  categoryPath3_catMap: z.union([z.array(z.string()), z.null()]).optional(),
  categoryPath3_fq: z.union([z.array(z.string()), z.null()]).optional(),
  categoryPathId1: z.union([z.array(z.string()), z.null()]).optional(),
  categoryPathId2: z.union([z.array(z.string()), z.null()]).optional(),
  categoryPathId3: z.union([z.array(z.string()), z.null()]).optional(),
  complete_imageUrl: z.union([z.null(), z.string()]).optional(),
  doctype: z.string(),
  imageUrl: z.union([z.array(z.string()), z.null()]).optional(),
  lowest_variant_adjustedPrice: z.union([z.number(), z.null()]).optional(),
  lowest_variant_price: z.union([z.number(), z.null()]).optional(),
  lowest_variant_recordId: z.union([z.null(), z.string()]).optional(),
  mfi_ItemId: z.union([z.null(), z.string()]).optional(),
  parent_unbxd: z.boolean(),
  recs_color_vid_mapping: z.union([z.array(z.string()), z.null()]).optional(),
  storeSaleQty: z.union([z.number(), z.null()]).optional(),
  syn_unx_auto: z.union([z.array(z.string()), z.null()]).optional(),
  syn_unx_auto_unstemmed: z.union([z.array(z.string()), z.null()]).optional(),
  timeStamp_unbxd: z.number(),
  tryinstore: z.union([z.array(z.string()), z.null()]).optional(),
  u_categoryPathId: z.union([z.array(z.string()), z.null()]).optional(),
  unbxdFeedId: z.string(),
  uniqueId: z.string(),
  variantCount: z.union([z.number(), z.null()]).optional(),
  variantTotal: z.union([z.number(), z.null()]).optional(),
  _root_: z.union([z.null(), z.string()]).optional(),
  score: z.union([z.number(), z.null()]).optional(),
  variants: z.union([z.array(variantSchema), z.null()]).optional(),
  relevantDocument: z.union([z.null(), z.string()]).optional(),
  result_unbxd_double: z.union([z.number(), z.null()]).optional(),
  revenues_unbxd_double: z.union([z.number(), z.null()]).optional(),
  source_unbxd_fields: z.union([z.array(z.string()), z.null()]).optional(),
  carts_unbxd_double: z.union([z.number(), z.null()]).optional(),
  suggestion_length_unbxd_double: z.union([z.number(), z.null()]).optional(),
  orders_unbxd_double: z.union([z.number(), z.null()]).optional(),
  clicks_unbxd_double: z.union([z.number(), z.null()]).optional(),
  unbxdAutosuggestScore: z.union([z.number(), z.null()]).optional(),
  hits_unbxd_double: z.union([z.number(), z.null()]).optional(),
  frequency_unbxd_double: z.union([z.number(), z.null()]).optional()
})

const productsSchema = z.array(productSchema)

const responseSchema = z.object({
  numberOfProducts: z.number(),
  start: z.number(),
  products: productsSchema
})

const autoSuggestResponseSchema = z.object({
  searchMetaData: searchMetaDataSchema,
  response: responseSchema
})

const productSearchItemSchema = z.object({
  productSku: z.string(),
  productName: z.string(),
  productType: z.enum(['Mattresses', 'Foundations & Bases', 'Bedding', 'Pillows', 'Furniture']).optional().nullable()
})

const apiInputSchema = z.object({
  searchTerm: z.string(),
  popularProducts: z
    .object({
      count: z.number()
    })
    .optional(),
  inFields: z
    .object({
      count: z.number()
    })
    .optional(),
  topQueries: z
    .object({
      count: z.number()
    })
    .optional(),
  keywordSuggestions: z
    .object({
      count: z.number()
    })
    .optional(),
  uid: z.string().optional(),
  filterCategories: z.array(z.enum(['Mattresses', 'Foundations & Bases', 'Bedding', 'Pillows', 'Furniture'])).optional()
})

const productSearchSchema = z.array(productSearchItemSchema)

/** Export Types */
type AutoSuggestResponse = z.infer<typeof autoSuggestResponseSchema>
type Product = z.infer<typeof productSchema>
type Products = z.infer<typeof productsSchema>
type ProductSearchInput = z.infer<typeof apiInputSchema>
type ProductSearchResultItem = z.infer<typeof productSearchItemSchema>
type ProductSearchResults = z.infer<typeof productSearchSchema>
type QueryParams = z.infer<typeof queryParamsSchema>
type Response = z.infer<typeof responseSchema>
type SearchMetaData = z.infer<typeof searchMetaDataSchema>
type Variant = z.infer<typeof variantSchema>

/** Export Schemas */
export {
  apiInputSchema,
  autoSuggestResponseSchema,
  errorSchema,
  productSchema,
  productSearchSchema,
  queryParamsSchema,
  responseSchema,
  searchMetaDataSchema,
  variantSchema
}

/** Export Types */
export type {
  AutoSuggestResponse,
  Product,
  Products,
  ProductSearchResultItem,
  ProductSearchInput,
  ProductSearchResults,
  QueryParams,
  Response,
  SearchMetaData,
  Variant
}
