import React, { FunctionComponent, useCallback, useState } from 'react'
import clsx from 'clsx'
import Button from '../Button'
import styles from './CopyButton.module.scss'
import { copyText } from '../utils/copyText'
import CopyIcon from '../Icons/CopyIcon'
import CopyRedesign from '../Icons/CopyRedesign'

type CopyButtonProps = {
  displayText: string
  textToCopy?: string
  iconOnRight?: boolean
  iconFillColor?: string | undefined
  iconBackgroundFillColor?: string | undefined
  variant?: 'current' | 'redesigned'
}

const CopyButton: FunctionComponent<CopyButtonProps & React.HTMLAttributes<HTMLButtonElement>> = ({
  displayText,
  textToCopy = '',
  iconOnRight = false,
  iconFillColor = undefined,
  iconBackgroundFillColor = undefined,
  variant = 'current',
  ...rest
}) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyOnClick = useCallback(() => {
    copyText(textToCopy || displayText).then(() => {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 1500)
    })
  }, [displayText, textToCopy])
  const { copyButton: copyButtonStyles } = styles
  const iconArgs = { iconFillColor, iconBackgroundFillColor }
  const copyButtonContent = iconOnRight ? (
    <>
      {displayText}
      <CopyIcon {...iconArgs} />
    </>
  ) : (
    <>
      <CopyIcon {...iconArgs} />
      {displayText}
    </>
  )
  return (
    <>
      {variant === 'current' ? (
        <Button
          {...rest}
          btnType="link"
          size="sm"
          onKeyDown={copyOnClick}
          onClick={copyOnClick}
          className={clsx([copyButtonStyles])}
        >
          {isCopied ? 'Copied!' : copyButtonContent}
        </Button>
      ) : (
        <Button type="button" className={isCopied ? styles.buttonCopied : styles.buttonCopy} onClick={copyOnClick}>
          {isCopied ? <CopyRedesign fill="white" /> : <CopyRedesign />}
        </Button>
      )}
    </>
  )
}

CopyButton.defaultProps = {
  textToCopy: '',
  iconOnRight: false,
  iconFillColor: undefined,
  iconBackgroundFillColor: undefined
}

export default CopyButton
