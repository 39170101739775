import React, { ChangeEvent, useEffect, useRef } from 'react'
import Input from '../Input'
import Typography from '../Typography'
import NoteTitleProps, { NoteTitleIds } from './interfaces/NoteTitle'
import { formatCustomerData, fullCustomerName } from './utils'
import styles from './styles/notes.module.scss'
import { NOTE_TITLE_MAX_LENGTH, EMPTY_NOTE_DEFAULT_TITLE } from '../NotesEditorProvider/consts/notesEditor'

const NoteTitle = ({
  analyticsPrefixId = '',
  currCustomer,
  title,
  onInputChange,
  setExpanded,
  onAddButtonClick,
  onProfileClick,
  isEditable = true
}: NoteTitleProps) => {
  const titleInputRef = useRef<HTMLInputElement>(null)

  const { NOTE_TITLE_SAVE_PROFILE_LINK, NOTE_TITLE_INPUT } = NoteTitleIds

  const handleProfileClick = () => {
    onProfileClick?.()
    setExpanded(false)
  }

  const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
    if (titleInputRef.current?.value === EMPTY_NOTE_DEFAULT_TITLE) e.target.select()
  }

  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus()
    }
  }, [])

  return (
    <div className={styles['floating-title']}>
      <Typography variant="h6" className={styles['floating-title-title']}>
        Note
      </Typography>

      <div id={`${analyticsPrefixId}${NOTE_TITLE_SAVE_PROFILE_LINK}`} className={styles['floating-title-customer']}>
        Customer:
        {currCustomer?.Id ? (
          <>
            <a onClick={handleProfileClick}>{fullCustomerName(currCustomer)}</a>
            {formatCustomerData(currCustomer)}
          </>
        ) : (
          <>
            <a onClick={onAddButtonClick}>+ Save Profile</a>
            <br />
            <span>Note cannot be saved without a linked profile</span>
          </>
        )}
      </div>
      <Input
        id={`${analyticsPrefixId}${NOTE_TITLE_INPUT}`}
        className={styles['floating-title-input']}
        error={title.length > NOTE_TITLE_MAX_LENGTH}
        inputMessage={
          title.length > NOTE_TITLE_MAX_LENGTH ? `Title may only be ${NOTE_TITLE_MAX_LENGTH} characters long` : ''
        }
        label="Title"
        name="title"
        value={title}
        onChange={onInputChange}
        onFocus={handleFocus}
        ref={titleInputRef}
        disabled={!isEditable}
      />
    </div>
  )
}

export default NoteTitle
