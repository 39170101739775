import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fill?: string
}

const ProductTypeFoundation: React.FunctionComponent<Props> = ({ width = '16', height = '16', fill = '#626366' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3955 9.33341H2.60187C2.42654 9.33341 2.28441 9.47555 2.28441 9.65087V10.2858C2.28441 10.4611 2.42654 10.6033 2.60187 10.6033H13.713V9.65087C13.713 9.47555 13.5709 9.33341 13.3955 9.33341ZM2.60187 8.38103C1.90056 8.38103 1.33203 8.94956 1.33203 9.65087V10.2858C1.33203 10.9871 1.90056 11.5556 2.60187 11.5556H14.6654V9.65087C14.6654 8.94956 14.0968 8.38103 13.3955 8.38103H2.60187Z"
      fill={fill}
    />
    <path
      d="M2.28441 13.4604C1.75843 13.4604 1.33203 13.034 1.33203 12.508L1.33203 3.61913C1.33203 3.09314 1.75843 2.66675 2.28441 2.66675C2.8104 2.66675 3.23679 3.09314 3.23679 3.61913L3.23679 12.508C3.23679 13.034 2.8104 13.4604 2.28441 13.4604Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0781 6.79371H8.74807C8.25797 6.79371 7.78162 6.6317 7.39315 6.33288L4.65134 4.22379C4.50887 4.1142 4.30392 4.14463 4.19945 4.29089L4.07578 4.46403C3.97778 4.60123 4.00413 4.79111 4.1358 4.89644L7.61091 7.67653C7.6672 7.72156 7.73714 7.7461 7.80922 7.7461H13.0781C13.2534 7.7461 13.3955 7.60396 13.3955 7.42863V7.11117C13.3955 6.93585 13.2534 6.79371 13.0781 6.79371ZM13.0781 5.84133H8.74807C8.46801 5.84133 8.19581 5.74875 7.97383 5.578L5.23201 3.46891C4.66215 3.03055 3.84235 3.15229 3.42447 3.73733L3.3008 3.91047C2.90879 4.45928 3.0142 5.21881 3.54085 5.64013L7.01596 8.42021C7.24112 8.60034 7.52088 8.69848 7.80922 8.69848H13.0781C13.7794 8.69848 14.3479 8.12995 14.3479 7.42863V7.11117C14.3479 6.40986 13.7794 5.84133 13.0781 5.84133Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7606 10.8807V12.5094C12.7606 13.0354 13.187 13.4604 13.713 13.4604C14.239 13.4604 14.6654 13.034 14.6654 12.508V10.9207H13.0781C12.9684 10.9207 12.8621 10.9068 12.7606 10.8807Z"
      fill={fill}
    />
  </svg>
)

export default ProductTypeFoundation
