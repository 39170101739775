export const statusValues = [
  'Open',
  'Open-Hold',
  'Closed-Not Purchased',
  'Closed-Purchased',
  'Closed-No Activity',
  'Closed-No Contact Info Provided',
  'Closed Lost',
  'Closed Won',
  'Closed Written',
  'Closed Delivered'
] as const

export const openStatusValues = statusValues.filter((x) => x.includes('Open'))
export const closedStatusValues = statusValues.filter((x) => x.includes('Closed'))

export type StatusType = typeof statusValues[number]

// The values that the frontend can display
export const mappedStatusValues = [
  'Open',
  'Open-Hold',
  'Closed-Not Purchased',
  'Closed-Purchased',
  'Closed-No Activity',
  'Closed-No Contact Info Provided'
] as const

// temp values to not fail procedures
export const communicationMethods = ['Call', 'Text', 'Email', 'Cell', 'Business', 'Home'] as const

// Web and Mattress Matcher are not values for user selection and will display on Lead Details when a lead was systematically created
export const sourceLeadMethods = [
  'Social Media',
  'Referral',
  'Phone',
  'Podium',
  'Events & Expositions',
  'Walk-in',
  'Other',
  'National Sales Referral',
  'Mattress Matcher',
  'Web'
] as const

export const stages = [
  'Digital Business Card',
  'Visit Recap',
  'First Check In',
  'Reconnect',
  'Completed',
  'Not Started'
] as const

export const stageStatuses = ['Open', 'Completed'] as const

export const MM_DOWN_MESSAGE = 'MM API appears to be down.'
export const NEO4J_DOWN_ERROR = 'Neo4j Server Unavailable'

export type ProductType = 'Mattresses' | 'Foundations & Bases' | 'Bedding' | 'Pillows' | 'Furniture'

// Define the mapping using the ProductType
export const PRODUCT_TYPES: Record<number, ProductType> = {
  5637147600: 'Mattresses',
  5637147587: 'Foundations & Bases',
  5637147593: 'Bedding',
  5637147606: 'Pillows',
  5637147577: 'Furniture'
}
